<template>
  <div class="oci-container-pj">
    <div class="oci-header">
      <b-row>     
      </b-row>          
    </div>
    <hr class="w-100">  
    <div class="wrapper">
    </div>
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BButton, 
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
  },
  computed: {
  },    
  data() {
    return {
      color: "#6E6B7B",
      scrollPosition: 0,
      message: '',
      showMessage: false,
      showLogin: false,
    }
  },
  created() {    
    this.OpenHelpPage()  
  },  
  methods: {
    OpenHelpPage() {
      window.open("https://wiki.promentum.info/wiki/lawmens/view/Operatives-App/", '_blank');
      this.$router.push('/');
    },   
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: 100%;
}

.sub-header-item-1 {
  grid-column: 1;
  backdrop-filter: #fff;
  width: 50%;
}

.sub-header-item-2 {
  grid-column: 2;
  background: #fff;
  width: 50%;
}

.wrapper {
  overflow: auto;
  height: 70vh;
  scrollbar-width: none;
}
 </style>
